import request from '@/utils/request'

//取会员信息
export function miMemberInfo(query) {
    return request({
        url: '/userInfo/miMemberInfo',
        method: 'get',
        params: query
    })
}

//取会员信息
export function miMemberInfo2(query) {
    return request({
        url: 'common/miMemberInfo',
        method: 'get',
        params: query
    })
}

//经销商资料维护
export function memberInfoEdit(data) {
    return request({
        url: '/userInfo/memberInfoEdit',
        method: 'post',
        data
    })
}

// 代办处基本信息更新
export function agentInfoEdit(data) {
    return request({
        url: '/userInfo/agentInfoEdit',
        method: 'post',
        data
    })
}

//更改密码
export function modifyPwd(data) {
    return request({
        url: '/userInfo/modifyPwd',
        method: 'post',
        data
    })
}

export function creditCardShow(query) {
    return request({
        url: '/miCreditCard/creditCardShow',
        method: 'get',
        params: query
    })
}
export function creditCardSave(data) {
    return request({
        url: '/miCreditCard/creditCardSave',
        method: 'post',
        data
    })
}
