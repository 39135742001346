<template>
  <div class="app-ChangePassword">
    <div class="ChangePassword-wrapper">
      <el-row>
        <el-col :span="24">
          <div style="display: flex">
            <el-col :span="24" style="margin-left: 20px">
              <div class="grid-content bg-purple-dark" style="text-align: left">
                <h1
                  style="
                    font-size: 30px;
                    font-weight: 500;
                    margin: 0px 0px 10px 0px;
                  "
                >
                  {{ this.$t('title.change.password') }}
                </h1>
                <hr style="opacity: 0.4;margin-bottom: 30px;" />
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane :label="$t('sysUser.userPassword')" name="first">
                    <el-form
                      :model="listQuery"
                      ref="ruleForm"
                      label-width="auto"
                      class="demo-ruleForm"
                    >
                    <el-form-item :label="$t('efubo.card.ps.old')" required>
                        <el-input v-model="listQuery.oldPassword"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('mi.member.pwd')" required>
                        <el-input v-model="listQuery.newPassword"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('mi.member.ConfirmPassword')" required>
                        <el-input v-model="listQuery.newPasswordTwo"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary"  @click="edit()">{{
                          this.$t('btn.continue')
                        }}</el-button>
                        <el-button style="margin-left: 20px">{{
                          this.$t('operation.button.cancel')
                        }}</el-button>
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane :label="$t('Change.transaction.password')" name="second">
                    <el-form
                      :model="listQuery"
                      ref="ruleForm"
                      label-width="auto"
                      class="demo-ruleForm"
                    >
                      <el-form-item :label="$t('efubo.card.ps.old')" required>
                        <el-input v-model="listQuery.oldPassword"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('mi.member.pwd')" required>
                        <el-input v-model="listQuery.repeatPassword"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('mi.member.ConfirmPassword')" required>
                        <el-input v-model="listQuery.repeatPasswordTwo"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="edit()">{{
                          this.$t('btn.continue')
                        }}</el-button>
                        <el-button style="margin-left: 20px">{{
                          this.$t('operation.button.cancel')
                        }}</el-button>
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { modifyPwd } from '@/api/userInfo/userInfo.js'
export default {
  name: 'ChangePassword',
  data() {
    return {
      labelPosition: 'right',
      listLoading: false,
      btnloading: false,
      activeName: 'first',
      listQuery: {
        page: 1,
        limit: 20,
        oldPassword:'',
        repeatPassword:'',
        repeatPasswordTwo:'',
        newPassword:'',
        newPasswordTwo:'',
        modifyType: 1,
      },
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleClick(tab) {
      // console.log(tab.index)
      if (tab.index==0) {
        this.listQuery.modifyType=1
        this.listQuery.oldPassword = ''
        this.listQuery.repeatPassword = ''
        this.listQuery.repeatPasswordTwo = ''
      }
      if (tab.index==1) {
        this.listQuery.modifyType=2
        this.listQuery.oldPassword = ''
        this.listQuery.newPassword = ''
        this.listQuery.newPasswordTwo = ''
      }
    },
    edit() {
      this.btnloading = true
      modifyPwd({...this.listQuery}).then(res => {
        if (res.success) {
          this.btnloading = false
          this.$notify({
            title: '',
            message: this.$t('ps.find.pass.mess5'),
            type: 'success'
          })
          this.listQuery.oldPassword = ''
          this.listQuery.newPassword = ''
          this.listQuery.newPasswordTwo = ''
          this.listQuery.repeatPassword = ''
          this.listQuery.repeatPasswordTwo = ''
        }
      }).catch(err => {
        console.warn(err)
        this.btnloading = false
      })
    },
    Continue() {
      // this.$router.push(`/cash-flow`)
    },
  },
}
</script>

<style scoped lang="scss">
.el-tabs__item.is-active {
  color: #f39f43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}
.MsoNormal {
  margin: 10px 0px;
  text-align: left;
}
.el-button + .el-button {
  margin-left: 0;
}
.btn {
  width: 100%;
  text-align: left;
  border-bottom: 0px;
  border-radius: 0;
}
</style>
